/*----- 12. image slider style  -----*/

.single-image {
  overflow: hidden;
  a {
    img {
      width: 100%;
      transition: all 0.5s ease 0s;
      transform: scale(1);
    }
  }
  &:hover a img {
    transform: scale(1.1);
  }
}

.product-image-description {
  .alice-carousel {
    text-align: center !important;
    background-color: #f5f5f5 !important;
    border-radius: 15px;
  }

  .alice-carousel__slide-info {
    color: white !important;
    background-color: black !important;
    right: 5% !important;
  }

  .alice-carousel__dots{
    margin-top: 5px !important;
  }

  .alice-carousel__dots-item:not(.__custom):hover, .alice-carousel__dots-item:not(.__custom).__active {
    background-color: black !important;
  }

  .alice-carousel__dots-item:not(.__custom) {
    background-color: lightgrey !important;
  }
}

.alice-carousel__dots {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}

.alice-carousel__dots-item:not(.__custom):hover, .alice-carousel__dots-item:not(.__custom).__active {
  background-color: black !important;
}

.alice-carousel__dots-item:not(.__custom){
    background-color: lightgrey !important;
}